<template>
  <b-jumbotron
    header="404"
    class="text-center bg-blue text-warning fontStandard"
    :style="this['events/handleMinHeight']"
  >
    <p class="text-warning">La page demandée est introuvable ou a été supprimé</p>
    <b-button variant="primary" @click="handleClick">Retour à l'acceuil</b-button>
  </b-jumbotron>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["events/handleMinHeight"]),
  },
  methods: {
    handleClick() {
      this.$router.push("/");
    },
  },
};
</script>
